import React from 'react';
import './Footer.css';

export function Footer(): React.ReactElement {
    return (
        <footer className={'App-footer'}>
            <p>By Alexandre Devaux</p>
        </footer>
    );
}
