import React from 'react';
import './Game.css';
import { Row } from '../Centium';

interface GameProps {
    csvFileContent: Row[];
}

export function Game({ csvFileContent }: GameProps): React.ReactElement {
    const [rightAnswers, setRightAnswers] = React.useState(0);
    const [wrongAnswers, setWrongAnswers] = React.useState(0);
    const [minNumber, setMinNumber] = React.useState(0);
    const [maxNumber, setMaxNumber] = React.useState(100);
    const [currentNumber, setCurrentNumber] = React.useState(0);
    const [currentAnswer, setCurrentAnswer] = React.useState('');
    const [isStarted, setIsStarted] = React.useState(false);
    const [previousSolution, setPreviousSolution] = React.useState<Row>();

    function renderScore(): React.ReactNode {
        return (
            <div className={'Game-content__score'}>
                <div className={'Game-content__score__right'}>
                    <span className={'Game-content__score__right__value'}>{rightAnswers}</span>
                </div>
                <div className={'Game-content__score__wrong'}>
                    <span className={'Game-content__score__wrong__value'}>{wrongAnswers}</span>
                </div>
            </div>
        );
    }
    function renderMinMax(): React.ReactNode {
        return (
            <>
                <div className={'Game-MinNumber'}>
                    <div>Min {minNumber}</div>
                    <input
                        type={'range'}
                        min={0}
                        max={maxNumber - 1}
                        value={minNumber}
                        onChange={(e) => setMinNumber(e.target.valueAsNumber)}
                    />
                </div>
                <div className={'Game-MaxNumber'}>
                    <div>Max {maxNumber}</div>
                    <input
                        type={'range'}
                        min={minNumber + 1}
                        max={100}
                        value={maxNumber}
                        onChange={(e) => setMaxNumber(e.target.valueAsNumber)}
                    />
                </div>
            </>
        );
    }

    function handleNewNumber(): void {
        const newNumber = Math.floor(Math.random() * (maxNumber - minNumber + 1)) + minNumber;
        setCurrentAnswer('');
        setCurrentNumber(newNumber);
    }

    function handleReset(): void {
        setRightAnswers(0);
        setWrongAnswers(0);
        setMinNumber(0);
        setMaxNumber(100);
        setCurrentNumber(0);
        setCurrentAnswer('');
        setIsStarted(false);
        setPreviousSolution(undefined);
    }

    function handleAnswer(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        if (
            csvFileContent.find((row) => row.number === currentNumber) === undefined ||
            csvFileContent.find((row) => row.number === currentNumber)?.word === ''
        ) {
            alert('No solution found for this number, check that your max and min are correct');
        } else if (
            currentAnswer.toLowerCase() === csvFileContent.find((r) => r.number === currentNumber)?.word?.toLowerCase()
        ) {
            setRightAnswers(rightAnswers + 1);
            setPreviousSolution(csvFileContent.find((r) => r.number === currentNumber));
        } else {
            setWrongAnswers(wrongAnswers + 1);
            setPreviousSolution(csvFileContent.find((r) => r.number === currentNumber));
        }
        handleNewNumber();
    }

    function startGame(): void {
        setIsStarted(true);
        handleNewNumber();
    }

    return (
        <div className={'Game'}>
            <div className={'Game-content'}>
                {renderMinMax()}
                {renderScore()}
                {!isStarted && <button onClick={startGame}>{'Start'}</button>}
                {isStarted && (
                    <>
                        <div style={{ fontSize: '3rem' }}>{currentNumber}</div>
                        <form onSubmit={(e) => handleAnswer(e)} className={'Game-form'}>
                            <input
                                type={'text'}
                                value={currentAnswer}
                                onChange={(e) => setCurrentAnswer(e.target.value)}
                            />
                            <button type={'submit'}>Submit</button>
                        </form>
                        {previousSolution?.number != null && previousSolution.word != null && (
                            <div>
                                <div>{`Previous solution: ${previousSolution.number} - ${previousSolution.word}`}</div>
                                <button onClick={handleReset}>Reset</button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
