import { Row } from './Centium';

export function csvStringToArrrayOfObjects(str: string): Row[] {
    // remove '\r' from str
    str = str.replace(/\r/g, '');
    const headers = str.slice(0, str.indexOf('\n')).split(',');
    console.log(headers);
    const idxNumber = headers.indexOf('number');
    if (idxNumber === -1) {
        throw new Error('number column not found');
    }
    const idxWord = headers.indexOf('word');
    if (idxWord === -1) {
        throw new Error('word column not found');
    }
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');

    const arr = rows.map(function (row) {
        const values = row.split(',');
        const el = headers.reduce<Row>(
            function (object, header, index) {
                if (index === idxNumber) {
                    object.number = Number(values[index]);
                }
                if (index === idxWord) {
                    object.word = values[index];
                }
                return object;
            },
            { number: undefined, word: undefined },
        );
        return el;
    });
    return arr;
}
