import React from 'react';

import './CsvForm.css';

export function CsvForm(props: {
    onSubmit: (e: any) => void;
    onChange: (e: any) => void;
    csvFile: File | null;
}): React.ReactElement {
    return (
        <div className={'Section-form'}>
            <form onSubmit={props.onSubmit}>
                <input type="file" id="file" accept=".csv" onChange={props.onChange} />
                <button type="submit">Submit</button>
            </form>
            <h2 className={'File-selected'}>{props.csvFile != null ? props.csvFile.name : 'No file selected'}</h2>
        </div>
    );
}
