import React from 'react';

export function useCsv(): [File | null, (e: React.ChangeEvent<HTMLInputElement>) => void] {
    const [csvFile, setCsvFile] = React.useState<File | null>(null);

    function handleUpload(e: React.ChangeEvent<HTMLInputElement>): void {
        if (e.target.files != null && e.target.files.length > 0) {
            setCsvFile(e.target.files[0]);
        }
    }

    return [csvFile, handleUpload];
}
